<template>
  <div v-if="loading" class="tw-flex tw-justify-center tw-items-center tw-h-full">
    <i class="fal fa-spinner-third fa-spin tw-text-xl" />
  </div>

  <div v-else-if="estimate" class="tw-p-2">
    <button
      type="button"
      title="Terug naar overzicht"
      class="link tw-font-semibold tw-mb-4 hover:tw-underline focus:tw-underline"
      @click="showEstimatesModal"
    >
      <i class="fas fa-arrow-left tw-mr-1" />
      Terug naar overzicht
    </button>

    <div class="tw-flex tw-justify-between tw-flex-wrap tw-gap-2 tw-items-end tw-max-w-3xl">
      <div>
        <h2 class="tw-my-0 tw-text-2xl">Schattingsrapport</h2>
        <span v-if="estimate.created_by" class="tw-text-xs tw-italic">
          Gemaakt door {{ estimate.created_by.first_name }} {{ estimate.created_by.last_name }}
          om {{ estimate.created_on | date-day }}
        </span>
      </div>

      <span class="tw-text-xs tw-italic tw-text-green-500">
        <i class="far fa-check" />
        Alle data wordt automatisch opgeslagen
      </span>
    </div>

    <FormulateForm
      :values="estimate"
      name="updateEstimateForm"
      title="Schattingsrapport"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      class="tw-my-5 tw-w-full md:tw-max-w-3xl"
    >
      <FormulateInput
        type="text"
        name="title"
        label="Titel schattingsrapport"
        validation="required:trim"
        :input-class="['tw-h-8 tw-text-sm']"
        outer-class="tw-m-0"
        @change="handleEstimateValueUpdate('title', $event.target.value)"
      />

      <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          type="select"
          name="reason"
          label="Doel van de schatting"
          placeholder="Selecteer een doel"
          :options="reasonOptions"
          :input-class="['tw-h-8 tw-text-sm']"
          outer-class="tw-m-0 tw-col-span-2"
          @change="handleEstimateValueUpdate('reason', $event.target.value)"
        />
        <FormulateInput
          v-model="estimate.language"
          type="select"
          name="language"
          label="Taal"
          :options="USER_LANG_OPTIONS"
          validation="required"
          :input-class="['tw-h-8 tw-text-sm']"
          outer-class="tw-m-0"
          @change="handleEstimateValueUpdate('language', $event.target.value)"
        />
      </div>
    </FormulateForm>

    <!-- IMP: We use @change instead of @input, because we use html input validations and we validate & save the value upon the user leaving the field. -->

    <!-- We use a negative margin to nullify the effect ot tw-p-2 on the top container -->
    <div class="tw-mt-10 tw--mx-2 tw-overflow-x-auto tw-max-w-7xl">
      <button
        type="button"
        title="Pdf genereren"
        class="action tw-mx-2 tw-mb-4 tw-bg-success"
        :disabled="!calculatedIntrinsicValue || estimate.loading_report"
        @click="showPropertyEstimateReportPreviewModal"
      >
        <i
          :class="[
            'tw-mr-1 fas',
            estimate.loading_report
              ? 'fa-spinner-third fa-spin'
              : 'fa-file-pdf'
          ]"
        />
        Rapport genereren
      </button>

      <!-- We use tw-ml-2 to make space for the add-button to display properly -->
      <!-- We use a calculated max width to prevent horizontal scrolling just due to the ml-2 -->
      <table
        class="calculation-table tw-ml-2 tw-w-full tw-border-collapse"
        :style="{ maxWidth: 'calc(100% - 8px)' }"
      >
        <thead>
          <tr class="tw-bg-gray-f9">
            <th colspan="3" class="!tw-p-3 tw-min-w-[500px] tw-relative">
              Indeling
              <!-- Add a new item at the top, -1 is used as index because currently no item exists and we want to insert at 0 position -->
              <!-- Don't allow creating lines when all the top level options have been chosen. -->
              <button
                v-if="numberOfAllowedTopLines !== estimate.lines.length "
                type="button"
                title="Lijn toevoegen"
                class="add-button"
                @click="addLine(estimate, -1)"
              >
                <i class="far fa-plus" />
              </button>
            </th>
            <th class="!tw-p-3 tw-min-w-[90px] tw-text-right">m²</th>
            <th class="!tw-p-3 tw-min-w-[90px] tw-text-right">Factor</th>
            <th class="!tw-p-3 tw-min-w-[130px] tw-text-right">Min waarde m²</th>
            <th class="!tw-p-3 tw-min-w-[120px] tw-text-right">Totaal min</th>
            <th class="!tw-p-3 tw-min-w-[130px] tw-text-right">Max waarde m²</th>
            <th class="!tw-p-3 tw-min-w-[120px] tw-text-right">Totaal max</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in estimate.lines">
            <tr :key="`item-${item.id}`" class="tw-font-bold">
              <td class="tw-relative tw-min-w-[150px]">
                <!-- We do not allow free input for the top most levels as their names are used in calculations -->
                <span class="tw-pl-3 tw-pr-5 tw-py-1.5">
                  {{ item.name }}
                </span>
                <select
                  :id="`select_name_${item.id}`"
                  :value="item.name"
                  class="select-name"
                  @change="selectedLineName($event, item, topLevelOptions, true)"
                >
                  <!-- Blank option for Chrome as it shows the first option selected by default, even when the name is empty -->
                  <!-- We don't want to allow the users to clear the name of the top most level rows -->
                  <option v-if="!item.name" value="" disabled />

                  <!-- We disable the options that are already selected in order to not allow duplicate line creation for the top level -->
                  <option
                    v-for="option in topLevelOptions"
                    :key="option.id"
                    :value="option[`name_${estimate.language}`]"
                    :disabled="isNameTakenByAnotherItem(option[`name_${estimate.language}`], index)"
                  >
                    {{ option[`name_${estimate.language}`] }}
                  </option>
                </select>
                <!-- Don't allow creating lines when all the top level options have been chosen. -->
                <button
                  v-if="numberOfAllowedTopLines !== estimate.lines.length"
                  type="button"
                  title="Lijn toevoegen"
                  class="add-button"
                  @click="addLine(estimate, index)"
                >
                  <i class="far fa-plus" />
                </button>
              </td>

              <!-- Min width added on cells below to maintain a structure with min width -->
              <td class="tw-relative tw-min-w-[200px]">
                <!-- Add a new child at the top, -1 is used as index because currently no item exists and we want to insert at 0 position -->
                <button
                  type="button"
                  title="Voeg vloer toe"
                  class="add-button"
                  @click="addLine(item, -1)"
                >
                  <i class="far fa-plus" />
                </button>
              </td>

              <!-- start: Empty cell to maintain the structure -->
              <td class="tw-min-w-[150px]" />
              <!-- end: Empty cell to maintain the structure -->

              <td>
                <NumberMaskInput
                  v-model="item.surface"
                  :id="`surface_${item.id}`"
                  min="0"
                  :allow-negative="false"
                  :config="{ style: 'decimal' }"
                  :post-format="value => value + ' m²'"
                  :disabled="item.lines.length !== 0"
                  class="input-cell hide-number-input-arrows tw-text-right"
                  @change="handleNumberUpdate(item)"
                />
              </td>
              <td>
                <NumberMaskInput
                  v-model="item.factor"
                  :id="`factor_${item.id}`"
                  min="0"
                  max="200"
                  :allow-negative="false"
                  :config="PERCENT_INPUT_CONFIG"
                  :disabled="item.lines.length !== 0"
                  class="input-cell hide-number-input-arrows tw-text-right"
                  @change="handleNumberUpdate(item)"
                />
              </td>
              <td>
                <NumberMaskInput
                  v-model="item.price_per_square_meter_min"
                  :id="`price_per_square_meter_min_${item.id}`"
                  :disabled="item.lines.length !== 0"
                  class="input-cell hide-number-input-arrows tw-text-right"
                  @change="handleNumberUpdate(item)"
                />
              </td>
              <td>
                <NumberMaskInput
                  v-model="item.total_value_min"
                  :id="`total_value_min_${item.id}`"
                  :disabled="item.lines.length !== 0"
                  class="input-cell hide-number-input-arrows tw-text-right"
                  @change="handleTotalsUpdate(item)"
                />
              </td>
              <td>
                <NumberMaskInput
                  v-model="item.price_per_square_meter_max"
                  :id="`price_per_square_meter_max_${item.id}`"
                  :disabled="item.lines.length !== 0"
                  class="input-cell hide-number-input-arrows tw-text-right"
                  @change="handleNumberUpdate(item)"
                />
              </td>
              <td>
                <NumberMaskInput
                  v-model="item.total_value_max"
                  :id="`total_value_max_${item.id}`"
                  :disabled="item.lines.length !== 0"
                  class="input-cell hide-number-input-arrows tw-text-right"
                  @change="handleTotalsUpdate(item)"
                />
              </td>
              <!-- Cannot delete lines with children -->
              <button
                v-if="item.lines.length === 0"
                type="button"
                class="delete-button"
                @click="deleteLine(null, index, item)"
              >
                <i class="fas fa-trash" />
              </button>
            </tr>

            <template v-for="(child, childIndex) in item.lines">
              <tr :key="`child-${child.id}`" :class="{ 'tw-font-bold': child.lines.length }">
                <td class="tw-relative">
                  <!--
                    To allow inserting top level lines below and above, irrespective of children.
                    We use the top level item's index to maintain order.
                  -->
                  <button
                    v-if="
                      child.lines.length === 0 // Check if there are no children as we cannot create new lines in between.
                      && childIndex === item.lines.length - 1 // Check if this is the last child.
                      && numberOfAllowedTopLines !== estimate.lines.length // Check if we are allowed to create more lines.
                    "
                    type="button"
                    title="Lijn toevoegen"
                    class="add-button"
                    @click="addLine(estimate, index)"
                  >
                    <i class="far fa-plus" />
                  </button>
                  <!-- if the button is not rendered, we just want an empty cell to maintain structure. -->
                </td>

                <td class="tw-relative">
                  <!-- We force the input text to be selected upon clicking the input too, not just keyboard focus -->
                  <input
                    v-model="child.name"
                    :id="`input_name_${child.id}`"
                    type="text"
                    class="input-cell tw-pr-5"
                    @focus="$event.target.select()"
                    @change="updateLine(child.id, { name: $event.target.value })"
                  />

                  <!-- We don't use a v-model here because we use it on the input above, and we don't want this to get triggered each time it updates -->
                  <select
                    :id="`select_name_${child.id}`"
                    :value="child.name"
                    class="select-name"
                    @change="selectedLineName($event, child, floorLevelOptions)"
                  >
                    <!-- Blank option for Chrome as it shows the first option selected by default, even when the name is empty -->
                    <option value="" disabled />

                    <!-- Filter the options based on the top level parent -->
                    <option
                      v-for="option in floorLevelOptions.filter(opt => opt.parent_key === item.key)"
                      :key="option.id"
                      :value="option[`name_${estimate.language}`]"
                    >
                      {{ option[`name_${estimate.language}`] }}
                    </option>
                  </select>
                  <button
                    type="button"
                    title="Voeg vloer toe"
                    class="add-button"
                    @click="addLine(item, childIndex)"
                  >
                    <i class="far fa-plus" />
                  </button>
                </td>

                <td class="tw-relative">
                  <!-- Add a new child at the top, -1 is used as index because currently no item exists and we want to insert at 0 position -->
                  <button
                    type="button"
                    title="Voeg kamer toe"
                    class="add-button"
                    @click="addLine(child, -1)"
                  >
                    <i class="far fa-plus" />
                  </button>
                </td>

                <td>
                  <NumberMaskInput
                    v-model="child.surface"
                    :id="`surface_${child.id}`"
                    min="0"
                    :allow-negative="false"
                    :config="{ style: 'decimal' }"
                    :post-format="value => value + ' m²'"
                    :disabled="child.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleNumberUpdate(child, item)"
                  />
                </td>
                <td>
                  <NumberMaskInput
                    v-model="child.factor"
                    :id="`factor_${child.id}`"
                    min="0"
                    max="200"
                    :allow-negative="false"
                    :config="PERCENT_INPUT_CONFIG"
                    :disabled="child.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleNumberUpdate(child, item)"
                  />
                </td>
                <td>
                  <NumberMaskInput
                    v-model="child.price_per_square_meter_min"
                    :id="`price_per_square_meter_min_${child.id}`"
                    :disabled="child.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleNumberUpdate(child, item)"
                  />
                </td>
                <td>
                  <NumberMaskInput
                    v-model="child.total_value_min"
                    :id="`total_value_min_${child.id}`"
                    :disabled="child.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleTotalsUpdate(child, item)"
                  />
                </td>
                <td>
                  <NumberMaskInput
                    v-model="child.price_per_square_meter_max"
                    :id="`price_per_square_meter_max_${child.id}`"
                    :disabled="child.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleNumberUpdate(child, item)"
                  />
                </td>
                <td>
                  <NumberMaskInput
                    v-model="child.total_value_max"
                    :id="`total_value_max_${child.id}`"
                    :disabled="child.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleTotalsUpdate(child, item)"
                  />
                </td>
                <!-- Cannot delete lines with children -->
                <button
                  v-if="child.lines.length === 0"
                  type="button"
                  class="delete-button"
                  @click="deleteLine(item, childIndex, child)"
                >
                  <i class="fas fa-trash" />
                </button>
              </tr>

              <tr
                v-for="(grandchild, grandchildIndex) in child.lines"
                :key="`grandchild-${grandchild.id}`"
              >
                <td class="tw-relative">
                  <!--
                    To allow inserting top level lines below and above, irrespective of children.
                    We use the top level item's index to maintain order.
                  -->
                  <button
                    v-if="
                      childIndex === item.lines.length - 1 // Check if this is the last parent (child)
                      && grandchildIndex === child.lines.length - 1 // Check if this is the last grandchild
                      && numberOfAllowedTopLines !== estimate.lines.length // Check if we are allowed to create more lines.
                    "
                    type="button"
                    title="Lijn toevoegen"
                    class="add-button"
                    @click="addLine(estimate, index)"
                  >
                    <i class="far fa-plus" />
                  </button>
                  <!-- if the button is not rendered, we just want an empty cell to maintain structure. -->
                </td>

                <td class="tw-relative">
                  <!-- Check if this is the last grandchild, so we can show the button to create the parent -->
                  <button
                    v-if="grandchildIndex === child.lines.length - 1"
                    type="button"
                    title="Voeg vloer toe"
                    class="add-button"
                    @click="addLine(item, childIndex)"
                  >
                    <i class="far fa-plus" />
                  </button>
                  <!-- Render an empty cell to maintain structure otherwise. -->
                </td>

                <td class="tw-relative">
                  <!-- We force the input text to be selected upon clicking the input too, not just keyboard focus -->
                  <input
                    v-model="grandchild.name"
                    :id="`input_name_${grandchild.id}`"
                    type="text"
                    class="input-cell tw-pr-5"
                    @focus="$event.target.select()"
                    @change="updateLine(grandchild.id, { name: $event.target.value })"
                  />

                  <!-- We don't use a v-model here because we use it on the input above, and we don't want this to get triggered each time it updates -->
                  <select
                    :id="`select_name_${grandchild.id}`"
                    :value="grandchild.name"
                    class="select-name"
                    @change="selectedLineName($event, grandchild, PROPERTY_ROOM_TYPES_TRANSLATED)"
                  >
                    <!-- Blank option for Chrome as it shows the first option selected by default, even when the name is empty -->
                    <option value="" disabled />

                    <option
                      v-for="option in PROPERTY_ROOM_TYPES_TRANSLATED"
                      :key="option.id"
                      :value="option[`name_${estimate.language}`]"
                    >
                      {{ option[`name_${estimate.language}`] }}
                    </option>
                  </select>
                  <button
                    type="button"
                    title="Voeg kamer toe"
                    class="add-button"
                    @click="addLine(child, grandchildIndex)"
                  >
                    <i class="far fa-plus" />
                  </button>
                </td>
                <td>
                  <NumberMaskInput
                    v-model="grandchild.surface"
                    :id="`surface_${grandchild.id}`"
                    min="0"
                    :allow-negative="false"
                    :config="{ style: 'decimal' }"
                    :post-format="value => value + ' m²'"
                    :disabled="grandchild.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleNumberUpdate(grandchild, child, item)"
                  />
                </td>
                <td>
                  <NumberMaskInput
                    v-model="grandchild.factor"
                    :id="`factor_${grandchild.id}`"
                    min="0"
                    max="200"
                    :allow-negative="false"
                    :config="PERCENT_INPUT_CONFIG"
                    :disabled="grandchild.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleNumberUpdate(grandchild, child, item)"
                  />
                </td>
                <td>
                  <NumberMaskInput
                    v-model="grandchild.price_per_square_meter_min"
                    :id="`price_per_square_meter_min_${grandchild.id}`"
                    :disabled="grandchild.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleNumberUpdate(grandchild, child, item)"
                  />
                </td>
                <td>
                  <NumberMaskInput
                    v-model="grandchild.total_value_min"
                    :id="`total_value_min_${grandchild.id}`"
                    :disabled="grandchild.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleTotalsUpdate(grandchild, child, item)"
                  />
                </td>
                <td>
                  <NumberMaskInput
                    v-model="grandchild.price_per_square_meter_max"
                    :id="`price_per_square_meter_max_${grandchild.id}`"
                    :disabled="grandchild.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleNumberUpdate(grandchild, child, item)"
                  />
                </td>
                <td>
                  <NumberMaskInput
                    v-model="grandchild.total_value_max"
                    :id="`total_value_max_${grandchild.id}`"
                    :disabled="grandchild.lines.length !== 0"
                    class="input-cell hide-number-input-arrows tw-text-right"
                    @change="handleTotalsUpdate(grandchild, child, item)"
                  />
                </td>
                <button
                  type="button"
                  class="delete-button"
                  @click="deleteLine(child, grandchildIndex, grandchild, item)"
                >
                  <i class="fas fa-trash" />
                </button>
              </tr>
            </template>
          </template>
          <!-- We add an empty row as a hack to prevent the table from scrolling vertically due to the add button expansion -->
          <tr class="tw-h-4"></tr>
        </tbody>
      </table>
    </div>

    <button
      type="button"
      title="Tabel als pdf genereren"
      :disabled="estimate.generating_table_pdf"
      class="action tw-bg-gray-500"
      @click="generateTableAsPdf"
    >
      <i
        :class="[
          'tw-mr-1 far',
          estimate.generating_table_pdf
            ? 'fa-spinner-third fa-spin'
            : 'fa-table'
        ]"
      />
      Tabel als pdf genereren
    </button>

    <div class="tw-my-7.5 tw-w-full md:tw-max-w-3xl">
      <h2 class="tw-my-0">Vetusteit</h2>
      <div class="tw-my-4 tw-flex tw-gap-4 tw-justify-between tw-items-center">
        <label for="vetusity_age" class="tw-text-sm tw-font-normal">
          Ouderdom (renovatiejaar/bouwjaar)
          <Tooltip class="tw-inline-block">
            <i class="fas fa-info-circle tw-cursor-pointer" />
            <template #popper>
              <table class="tw-w-full">
                <thead>
                  <tr>
                    <th class="tw-p-2 tw-text-center">Ouderdom</th>
                    <th class="tw-p-2 tw-text-center">Factor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="option in filterVetusityOptions('age')" :key="option.id">
                    <td class="tw-p-2 tw-text-center">{{ option.name }}</td>
                    <td class="tw-p-2 tw-text-center">{{ option.value }} %</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Tooltip>
        </label>
        <div class="tw-flex tw-gap-4">
          <select
            v-model="estimate.vetusity_age"
            id="vetusity_age_select"
            name="vetusity_age_select"
            class="formulate-select calculation-select"
            @change="handleEstimateValueUpdate('vetusity_age', $event.target.value)"
          >
            <!-- DEW-11367 Blank option that sets the value to null, requested by Jan -->
            <option :value="null"></option>
            <option v-for="option in filterVetusityOptions('age')"
              :key="option.id"
              :value="option.value"
            >
              {{ option.name }}
            </option>
          </select>
          <NumberMaskInput
            v-model="estimate.vetusity_age"
            id="vetusity_age"
            name="vetusity_age"
            max="0"
            step="0.01"
            :config="PERCENT_INPUT_CONFIG"
            class="calculation-input hide-number-input-arrows tw-w-[70px]"
            @change="handleEstimateValueUpdate('vetusity_age', $event)"
          />
        </div>
      </div>

      <div class="tw-my-4 tw-flex tw-gap-4 tw-justify-between tw-items-center">
        <label for="vetusity_maintenance" class="tw-text-sm tw-font-normal">
          Onderhoud
          <Tooltip class="tw-inline-block">
            <i class="fas fa-info-circle tw-cursor-pointer" />
            <template #popper>
              <table class="tw-w-full">
                <thead>
                  <tr>
                    <th class="tw-p-2 tw-text-center">Onderhoud</th>
                    <th class="tw-p-2 tw-text-center">Factor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="option in filterVetusityOptions('maintenance')" :key="option.id">
                    <td class="tw-p-2 tw-text-center">{{ option.name }}</td>
                    <td class="tw-p-2 tw-text-center">{{ option.value }} %</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Tooltip>
        </label>
        <div class="tw-flex tw-gap-4">
          <select
            v-model="estimate.vetusity_maintenance"
            id="vetusity_maintenance_select"
            name="vetusity_maintenance_select"
            class="formulate-select calculation-select"
            @change="handleEstimateValueUpdate('vetusity_maintenance', $event.target.value)"
          >
            <!-- DEW-11367 Blank option that sets the value to null, requested by Jan -->
            <option :value="null"></option>
            <option v-for="option in filterVetusityOptions('maintenance')"
              :key="option.id"
              :value="option.value"
            >
              {{ option.name }}
            </option>
          </select>
          <NumberMaskInput
            v-model="estimate.vetusity_maintenance"
            id="vetusity_maintenance"
            name="vetusity_maintenance"
            max="0"
            step="0.01"
            :config="PERCENT_INPUT_CONFIG"
            class="calculation-input hide-number-input-arrows tw-w-[70px]"
            @change="handleEstimateValueUpdate('vetusity_maintenance', $event)"
          />
        </div>
      </div>

      <div class="tw-my-4 tw-flex tw-gap-4 tw-justify-between tw-items-center">
        <label for="vetusity_usage" class="tw-text-sm tw-font-normal">
          Gebruik
          <Tooltip class="tw-inline-block">
            <i class="fas fa-info-circle tw-cursor-pointer" />
            <template #popper>
              <table class="tw-w-full">
                <thead>
                  <tr>
                    <th class="tw-p-2 tw-text-center">Gebruik</th>
                    <th class="tw-p-2 tw-text-center">Factor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="option in filterVetusityOptions('usage')" :key="option.id">
                    <td class="tw-p-2 tw-text-center">{{ option.name }}</td>
                    <td class="tw-p-2 tw-text-center">{{ option.value }} %</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Tooltip>
        </label>
        <div class="tw-flex tw-gap-4">
          <select
            v-model="estimate.vetusity_usage"
            id="vetusity_usage_select"
            name="vetusity_usage_select"
            class="formulate-select calculation-select"
            @change="handleEstimateValueUpdate('vetusity_usage', $event.target.value)"
          >
            <!-- DEW-11367 Blank option that sets the value to null, requested by Jan -->
            <option :value="null"></option>
            <option v-for="option in filterVetusityOptions('usage')"
              :key="option.id"
              :value="option.value"
            >
              {{ option.name }}
            </option>
          </select>
          <NumberMaskInput
            v-model="estimate.vetusity_usage"
            id="vetusity_usage"
            name="vetusity_usage"
            max="0"
            step="0.01"
            :config="PERCENT_INPUT_CONFIG"
            class="calculation-input hide-number-input-arrows tw-w-[70px]"
            @change="handleEstimateValueUpdate('vetusity_usage', $event)"
          />
        </div>
      </div>

      <div class="tw-my-4 tw-flex tw-gap-4 tw-justify-between tw-items-center">
        <label for="energetic_factor" class="tw-text-sm tw-font-normal">
          Energetische factor (geschat EPC-label)
          <Tooltip class="tw-inline-block">
            <i class="fas fa-info-circle tw-cursor-pointer" />
            <template #popper>
              <p v-if="!estimate.estimated_epc_label">
                Er is nog geen EPC-label toegewezen aan dit pand. Wat is het geschatte EPC-label?
                <br />
                Dit is enkel van belang om bij de waardebepaling rekening te houden met de enrgetische factor.
              </p>
              <table class="tw-w-full">
                <thead>
                  <tr>
                    <th class="tw-p-2 tw-text-center">EPC</th>
                    <th class="tw-p-2 tw-text-center">Factor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="option in energyFactorOptions" :key="option.id">
                    <td class="tw-p-2 tw-text-center">{{ option.name }}</td>
                    <td class="tw-p-2 tw-text-center">{{ option.value }} %</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Tooltip>
        </label>
        <div class="tw-flex tw-gap-4">
          <select
            v-model.number="estimate.estimated_epc_label"
            id="estimated_epc_label"
            name="estimated_epc_label"
            class="formulate-select calculation-select"
            @change="epcLabelSelected"
          >
            <option v-for="option in energyFactorOptions"
              :key="option.id"
              :value="option.epc_label"
            >
              {{ option.name }}
            </option>
          </select>
          <NumberMaskInput
            v-model="estimate.energetic_factor"
            id="energetic_factor"
            name="energetic_factor"
            max="0"
            step="0.01"
            :config="PERCENT_INPUT_CONFIG"
            class="calculation-input hide-number-input-arrows tw-w-[70px]"
            @change="handleEstimateValueUpdate('energetic_factor', $event)"
          />
        </div>
      </div>

      <div class="tw-my-4 tw-flex tw-flex-wrap tw-gap-4 tw-justify-between tw-font-bold tw-items-center tw-text-sm">
        <span>Totaal</span>
        <div>
          {{ totalVetusteitPercentage }}
          <span class="tw-mx-1">%</span>
        </div>
      </div>
    </div>

    <hr class="tw-max-w-3xl" />

    <div class="tw-my-7.5 tw-w-full md:tw-max-w-3xl tw-text-sm">
      <h2 class="tw-my-0">Totalen</h2>
      <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-font-bold">
        <div></div>
        <div class="tw-py-1 tw-text-right">Totaal minwaarde</div>
        <div class="tw-py-1 tw-text-right">Totaal maxwaarde</div>
      </div>
      <div
        v-for="total in calculatedTotals"
        :key="total.key"
        class="tw-grid tw-grid-cols-3 tw-gap-4 last:tw-font-bold"
      >
        <div class="tw-py-1">{{ total.label }}</div>
        <div class="tw-py-1 tw-text-right">{{ roundUpAndFormatCurrency(total.min_value) }}</div>
        <div class="tw-py-1 tw-text-right">{{ roundUpAndFormatCurrency(total.max_value) }}</div>
      </div>
    </div>

    <hr class="tw-max-w-3xl" />

    <div class="tw-my-7.5 tw-w-full md:tw-max-w-3xl">
      <h2 class="tw-my-0">Resultaten</h2>
      <div class="tw-text-right">
        <Tooltip class="tw-inline-block">
          <i class="fas fa-info-circle tw-cursor-pointer" />
          <template #popper>
            <p>Weergave in rapport (ja/nee)</p>
          </template>
        </Tooltip>
      </div>
      <div class="tw-my-4 md:tw-flex tw-gap-4 tw-justify-between tw-items-center">
        <label
          for="vetusity_age"
          :class="[
            'tw-text-sm tw-font-normal',
            { 'tw-opacity-60': !estimate.value_average_is_applicable }
          ]"
        >
          Gemiddelde nieuwbouwwaarde
        </label>
        <div class="tw-flex tw-gap-4 tw-items-center">
          <NumberMaskInput
            v-model="estimate.value_average"
            id="value_average"
            name="value_average"
            min="0"
            :disabled="!estimate.value_average_is_applicable"
            class="calculation-input hide-number-input-arrows tw-w-[165px]"
            @change="handleEstimateValueUpdate('value_average', $event)"
          />
          <input
            v-model="estimate.value_average_is_applicable"
            type="checkbox"
            name="value_average_is_applicable"
            class="!tw-mt-0"
            @change="handleEstimateValueUpdate('value_average_is_applicable', $event.target.checked)"
          />
        </div>
      </div>
      <div class="tw-my-4 md:tw-flex tw-gap-4 tw-justify-between tw-items-center">
        <label
          for="vetusity_age"
          :class="[
            'tw-text-sm tw-font-normal',
            { 'tw-opacity-60': !estimate.venality_min_is_applicable }
          ]"
        >
          Venaliteit min
          <Tooltip class="tw-inline-block">
            <i class="fas fa-info-circle tw-cursor-pointer" />
            <template #popper>
              Is de minwaarde, afgerond naar het eerste duizendtal boven.
            </template>
          </Tooltip>
        </label>
        <div class="tw-flex tw-gap-4 tw-justify-between tw-items-center">
          <span
            :class="[
              'tw-font-semibold tw-text-sm',
              { 'tw-line-through': estimate.venality_min_override },
              { 'tw-opacity-60': !estimate.venality_min_is_applicable }
            ]"
          >
            {{ roundUpAndFormatCurrency(calculatedVenalityMin) }}
          </span>
          <button
            v-if="!overrideToggles.venalityMin"
            type="button"
            class="edit-button"
            @click="toggle('venalityMin')"
          >
            <i class="fa fa-pencil" />
          </button>
          <NumberMaskInput
            v-else
            v-model="estimate.venality_min_override"
            id="venality_min_override"
            name="venality_min_override"
            placeholder="Overschreven waarde"
            min="0"
            :disabled="!estimate.venality_min_is_applicable"
            class="calculation-input hide-number-input-arrows tw-w-[165px]"
            @change="handleEstimateValueUpdate('venality_min_override', $event)"
          />
          <input
            v-model="estimate.venality_min_is_applicable"
            type="checkbox"
            name="venality_min_is_applicable"
            class="!tw-mt-0"
            @change="handleEstimateValueUpdate('venality_min_is_applicable', $event.target.checked)"
          />
        </div>
      </div>
      <div class="tw-my-4 md:tw-flex tw-gap-4 tw-justify-between tw-items-center">
        <label
          for="vetusity_age"
          :class="[
            'tw-text-sm tw-font-normal',
            { 'tw-opacity-60': !estimate.venality_max_is_applicable }
          ]"
        >
          Venaliteit max
          <Tooltip class="tw-inline-block">
            <i class="fas fa-info-circle tw-cursor-pointer" />
            <template #popper>
              Is de maxwaarde, afgerond naar het eerste duizendtal boven.
            </template>
          </Tooltip>
        </label>
        <div class="tw-flex tw-gap-4 tw-justify-between tw-items-center">
          <span
            :class="[
              'tw-font-semibold tw-text-sm',
              { 'tw-line-through': estimate.venality_max_override },
              { 'tw-opacity-60': !estimate.venality_max_is_applicable }
            ]"
          >
            {{ roundUpAndFormatCurrency(calculatedVenalityMax) }}
          </span>
          <div class="tw-flex tw-gap-x-4">
            <button
              v-if="!overrideToggles.venalityMax"
              type="button"
              class="edit-button"
              @click="toggle('venalityMax')"
            >
              <i class="fa fa-pencil" />
            </button>
            <NumberMaskInput
              v-else
              v-model="estimate.venality_max_override"
              id="venality_max_override"
              name="venality_max_override"
              placeholder="Overschreven waarde"
              min="0"
              :disabled="!estimate.venality_max_is_applicable"
              class="calculation-input hide-number-input-arrows tw-w-[165px]"
              @change="handleEstimateValueUpdate('venality_max_override', $event)"
            />
            <input
              v-model="estimate.venality_max_is_applicable"
              type="checkbox"
              name="venality_max_is_applicable"
              class="!tw-mt-0"
              @change="handleEstimateValueUpdate('venality_max_is_applicable', $event.target.checked)"
            />
          </div>
        </div>
      </div>

      <div class="tw-my-4 md:tw-flex tw-gap-4 tw-justify-between tw-items-center">
        <label
          for="value_intrinsic"
          :class="[
            'tw-text-sm tw-font-normal',
            { 'tw-opacity-60': !estimate.value_intrinsic_is_applicable }
          ]"
        >
          Intrinsieke waarde
          <Tooltip class="tw-inline-block">
            <i class="fas fa-info-circle tw-cursor-pointer" />
            <template #popper>
              Is de gemiddelde min- en maxwaarde, afgerond naar het eerste duizendtal boven.
            </template>
          </Tooltip>
        </label>
        <div class="tw-flex tw-gap-4 tw-justify-between tw-items-center">
          <span
            :class="[
              'tw-font-semibold tw-text-sm',
              { 'tw-line-through': estimate.value_intrinsic_override },
              { 'tw-opacity-60': !estimate.value_intrinsic_is_applicable }
            ]"
          >
            {{ roundUpAndFormatCurrency(calculatedIntrinsicValue) }}
          </span>
          <div class="tw-flex tw-gap-x-4">
            <button
              v-if="!overrideToggles.valueIntrinsic"
              type="button"
              class="edit-button"
              @click="toggle('valueIntrinsic')"
            >
              <i class="fa fa-pencil" />
            </button>
            <NumberMaskInput
              v-else
              v-model="estimate.value_intrinsic_override"
              id="value_intrinsic_override"
              name="value_intrinsic_override"
              placeholder="Overschreven waarde"
              min="0"
              :disabled="!estimate.value_intrinsic_is_applicable"
              class="calculation-input hide-number-input-arrows tw-w-[165px]"
              @change="handleEstimateValueUpdate('value_intrinsic_override', $event)"
            />
            <input
              v-model="estimate.value_intrinsic_is_applicable"
              type="checkbox"
              name="value_intrinsic_is_applicable"
              class="!tw-mt-0"
              @change="handleEstimateValueUpdate('value_intrinsic_is_applicable', $event.target.checked)"
            />
          </div>
        </div>
      </div>

      <div class="tw-my-4 md:tw-flex tw-gap-4 tw-justify-between tw-items-center">
        <label
          for="value_advice"
          :class="[
            'tw-text-sm tw-font-normal',
            { 'tw-opacity-60': !estimate.value_advice_is_applicable }
          ]"
        >
          Adviesvraagprijs
          <Tooltip class="tw-inline-block">
            <i class="fas fa-info-circle tw-cursor-pointer" />
            <template #popper>
              Is de gemiddelde min- en maxwaarde + 10%, afgerond naar het eerste duizendtal boven.
            </template>
          </Tooltip>
        </label>
        <div class="tw-flex tw-gap-4 tw-justify-between tw-items-center">
          <span
            :class="[
              'tw-font-semibold tw-text-sm',
              { 'tw-line-through': estimate.value_advice_override },
              { 'tw-opacity-60': !estimate.value_advice_is_applicable }
            ]"
          >
            {{ roundUpAndFormatCurrency(calculatedAdviceValue) }}
          </span>
          <div class="tw-flex tw-gap-x-4">
            <button
              v-if="!overrideToggles.valueAdvice"
              type="button"
              class="edit-button"
              @click="toggle('valueAdvice')"
            >
              <i class="fa fa-pencil" />
            </button>
            <NumberMaskInput
              v-else
              v-model="estimate.value_advice_override"
              id="value_advice_override"
              name="value_advice_override"
              placeholder="Overschreven waarde"
              min="0"
              :disabled="!estimate.value_advice_is_applicable"
              class="calculation-input hide-number-input-arrows tw-w-[165px]"
              @change="handleEstimateValueUpdate('value_advice_override', $event)"
            />
            <input
              v-model="estimate.value_advice_is_applicable"
              type="checkbox"
              name="value_advice_is_applicable"
              class="!tw-mt-0"
              @change="handleEstimateValueUpdate('value_advice_is_applicable', $event.target.checked)"
            />
          </div>
        </div>
      </div>

      <div class="tw-my-4 md:tw-flex tw-gap-4 tw-justify-between tw-items-center">
        <label
          for="value_presale"
          :class="[
            'tw-text-sm tw-font-normal',
            { 'tw-opacity-60': !estimate.value_presale_is_applicable }
          ]"
        >
          Pre-sale-/powersale-instelprijs (optioneel)
          <Tooltip class="tw-inline-block">
            <i class="fas fa-info-circle tw-cursor-pointer" />
            <template #popper>
              Is de minwaarde - 10%, afgerond naar het eerste duizendtal boven.
            </template>
          </Tooltip>
        </label>
        <div class="tw-flex tw-gap-4 tw-justify-between tw-items-center">
          <span
            :class="[
              'tw-font-semibold tw-text-sm',
              { 'tw-line-through': estimate.value_presale_override },
              { 'tw-opacity-60': !estimate.value_presale_is_applicable }
            ]"
          >
            {{ roundUpAndFormatCurrency(calculatedPresaleValue) }}
          </span>
          <div class="tw-flex tw-gap-x-4">
            <button
              v-if="!overrideToggles.valuePresale"
              type="button"
              class="edit-button"
              @click="toggle('valuePresale')"
            >
              <i class="fa fa-pencil" />
            </button>
            <NumberMaskInput
              v-else
              v-model="estimate.value_presale_override"
              id="value_presale_override"
              name="value_presale_override"
              placeholder="Overschreven waarde"
              min="0"
              :disabled="!estimate.value_presale_is_applicable"
              class="calculation-input hide-number-input-arrows tw-w-[165px]"
              @change="handleEstimateValueUpdate('value_presale_override', $event)"
            />
            <input
              v-model="estimate.value_presale_is_applicable"
              type="checkbox"
              name="value_presale_is_applicable"
              class="!tw-mt-0"
              @change="handleEstimateValueUpdate('value_presale_is_applicable', $event.target.checked)"
            />
          </div>
        </div>
      </div>
    </div>

    <PropertyEstimateReportPreviewModal
      ref="propertyEstimateReportPreviewModal"
      :property-id="propertyId"
      :region="property.region"
      :language="estimate.language"
      @saved="fetchEstimateDetails"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Tooltip } from 'floating-vue'
import { errorModal } from '@/modalMessages'

import EventBus from '@/components/iam/bus'
import NumberMaskInput from '@/components/NumberMaskInput'
import PropertyEstimateReportPreviewModal from '@/components/properties/PropertyEstimateReportPreviewModal'

import { PROPERTY_ROOM_TYPES_TRANSLATED } from '@/forms/selectOptions'
import { USER_LANG_OPTIONS, roundUpAndFormatCurrency } from '@/utils/helpers'

import {
  getEstimationReportData,
  getPropertyEstimateDetails,
  updatePropertyEstimate,
  createEstimateLine,
  updateEstimateLine,
  deleteEstimateLine
} from '@/services/properties'

import {
  getLevels,
  getReasons,
  getVetusityConfigRecords
} from '@/services/apiService'

import { generateEstimateReportGrid } from '@/services/gutenborgService'

export default {
  name: 'EstimationCalculator',
  components: {
    Tooltip,
    NumberMaskInput,
    PropertyEstimateReportPreviewModal
  },
  constants: {
    USER_LANG_OPTIONS,
    PROPERTY_ROOM_TYPES_TRANSLATED,
    PERCENT_INPUT_CONFIG: {
      style: 'unit',
      unit: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }
  },
  data () {
    return {
      loading: true,
      estimate: null,
      reasonOptions: [],
      vetusityConfig: [],
      topLevelOptions: [],
      floorLevelOptions: [],
      numberOfAllowedTopLines: null,
      overrideToggles: {}
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById']),

    propertyId () {
      return this.$route.params.id
    },
    estimateId () {
      return this.$route.params.estimateId
    },
    property () {
      return this.getPropertyById(this.propertyId)
    },
    energyFactorOptions () {
      const { region } = this.property
      const regionKeys = {
        0: 'energetic_factor_flanders', // Flanders
        1: 'energetic_factor_brussels', // Brussels
        2: 'energetic_factor_flanders' // Wallonia
      }
      const options = this.vetusityConfig.filter(config => config.type === regionKeys[region])
      // DEW-11367 Blank option that sets the value to null, requested by Jan
      options.unshift({ epc_label: null, value: null, name: '' })
      return options
    },
    selectedNames () {
      // Creates a Map of line names (in all languages) to their indices for efficient duplicate checking
      const map = new Map()
      if (this.estimate?.lines) {
        this.estimate.lines.forEach((line, index) => {
          // Find the matching option to get all language variants
          const option = this.topLevelOptions.find(opt =>
            opt.name_nl === line.name ||
            opt.name_en === line.name ||
            opt.name_fr === line.name
          )
          if (option) {
            map.set(option.name_nl, index)
            map.set(option.name_en, index)
            map.set(option.name_fr, index)
          }
        })
      }
      return map
    },

    totalVetusteitPercentage () {
      const age = parseFloat(this.estimate?.vetusity_age) || 0
      const usage = parseFloat(this.estimate?.vetusity_usage) || 0
      const maintenance = parseFloat(this.estimate?.vetusity_maintenance) || 0
      const energetic_factor = parseFloat(this.estimate?.energetic_factor) || 0

      if (age > 0 || usage > 0 || maintenance > 0 || energetic_factor > 0) return 'Vetusteit kan niet positief zijn'

      const total = (age + maintenance + usage + energetic_factor).toFixed(2)
      return parseFloat(total) // Convert back to number instead of string
    },
    calculatedTotals () {
      if (!this.estimate?.lines) return this.estimate.totals

      const getTotalsByKey = (key) => {
        const line = this.estimate.lines.find(line => line.key === key)
        return {
          min: parseFloat(line?.total_value_min || 0),
          max: parseFloat(line?.total_value_max || 0)
        }
      }

      // Get totals for each category
      const plotTotals = getTotalsByKey('plot')
      const constructionTotals = getTotalsByKey('construction')
      const diverseTotals = getTotalsByKey('miscellaneous')

      const vetusteitFactor = typeof this.totalVetusteitPercentage === 'number'
        ? (100 + this.totalVetusteitPercentage) / 100 // Percentage
        : 1 // If the Vetusity factor is not valid, we don't take it into account.

      // Apply vetusteit factor (negative percentage) to construction values
      const adjustedConstructionTotals = {
        min: constructionTotals.min * vetusteitFactor,
        max: constructionTotals.max * vetusteitFactor
      }

      // Calculate grand totals
      const grandTotal = {
        min: plotTotals.min + adjustedConstructionTotals.min + diverseTotals.min,
        max: plotTotals.max + adjustedConstructionTotals.max + diverseTotals.max
      }

      return [
        {
          key: 'plot',
          label: 'Totaal perceel',
          min_value: plotTotals.min,
          max_value: plotTotals.max
        },
        {
          key: 'construction',
          label: 'Totaal constructie',
          min_value: adjustedConstructionTotals.min,
          max_value: adjustedConstructionTotals.max
        },
        {
          key: 'miscellaneous',
          label: 'Totaal diversen',
          min_value: diverseTotals.min,
          max_value: diverseTotals.max
        },
        {
          key: 'totals',
          label: 'Totaal',
          min_value: grandTotal.min,
          max_value: grandTotal.max
        }
      ]
    },

    calculatedVenalityMin () {
      const minValue = this.calculatedTotals?.find(t => t.key === 'totals')?.min_value || 0
      return Math.ceil(minValue / 1000) * 1000 // Round up to a thousand
    },
    calculatedVenalityMax () {
      const maxValue = this.calculatedTotals?.find(t => t.key === 'totals')?.max_value || 0
      return Math.ceil(maxValue / 1000) * 1000 // Round up to a thousand
    },
    calculatedIntrinsicValue () {
      const totals = this.calculatedTotals?.find(t => t.key === 'totals')
      if (!totals) return 0
      const average = (totals.min_value + totals.max_value) / 2
      return Math.ceil(average / 1000) * 1000 // Round up to a thousand
    },
    calculatedAdviceValue () {
      const totals = this.calculatedTotals?.find(t => t.key === 'totals')
      if (!totals) return 0
      const average = (totals.min_value + totals.max_value) / 2
      const withMargin = average * 1.1 // Add 10%
      return Math.ceil(withMargin / 1000) * 1000 // Round up to a thousand
    },
    calculatedPresaleValue () {
      const minValue = this.calculatedTotals?.find(t => t.key === 'totals')?.min_value || 0
      const withDiscount = minValue * 0.9 // Subtract 10%
      return Math.ceil(withDiscount / 1000) * 1000 // Round up to a thousand
    },
    production () {
      return process.env.VUE_APP_ENVIRONMENT === 'prod'
    }
  },
  watch: {
    estimateId: {
      immediate: true,
      handler: 'init'
    }
  },
  methods: {
    roundUpAndFormatCurrency,
    toggle (type) {
      const value = this.overrideToggles[type]
      this.$set(this.overrideToggles, type, !value)
    },
    showEstimatesModal () {
      EventBus.$emit('showEstimatesModal')
    },
    showPropertyEstimateReportPreviewModal () {
      this.$set(this.estimate, 'loading_report', true)
      this.$refs.propertyEstimateReportPreviewModal.show(this.estimate)
    },

    isNameTakenByAnotherItem (name, currentIndex) {
      return this.selectedNames.has(name) && this.selectedNames.get(name) !== currentIndex
    },
    selectedLineName (event, item, options, include_level = false) {
      if (!item?.id) return

      const name = event.target.value
      this.$set(item, 'name', name)

      const option = options.find(opt => opt[`name_${this.estimate.language}`] === name)
      // First priority is user input, then default value, then 100
      const factor = item.factor || option?.default_factor_for_estimate_calculation || 100

      const payload = { name }
      // We need to send the level id when the top level names gets updated, so that the BE calculations work correctly
      if (include_level) payload.level = option?.id
      if (option?.key) this.$set(item, 'key', option.key)

      // We set the factor only for lines without children.
      if (!item.lines.length) {
        payload.factor = factor
        this.$set(item, 'factor', factor)
      }

      return this.updateLine(item.id, payload)
    },

    handleNumberUpdate (line, parent, grandparent) {
      // Values passed by the mask are always valid and can be updated directly
      const updatedLineValues = this.calculateTotals(line)

      // Update the parent and grandparent's totals if this line has parents
      if (parent) this.calculateParentTotals(parent)
      if (grandparent) this.calculateParentTotals(grandparent)
      return this.updateLine(line.id, updatedLineValues)
    },
    handleTotalsUpdate (line, parent, grandparent) {
      // Update the parent and grandparent's totals if this line has parents
      if (parent) this.calculateParentTotals(parent)
      if (grandparent) this.calculateParentTotals(grandparent)

      // Clear out factor, and min/max price per sq m values when totals are overriden, as the calculation doesn't make sense otherwise.
      this.$set(line, 'factor', null)
      this.$set(line, 'price_per_square_meter_min', null)
      this.$set(line, 'price_per_square_meter_max', null)

      return this.updateLine(line.id, line)
    },

    calculateParentTotals (line) {
      // Sum up totals from all children
      let total_surface = 0
      let total_value_min = 0
      let total_value_max = 0

      for (const child of line.lines) {
        total_surface += parseFloat(child.surface || 0)
        total_value_min += parseFloat(child.total_value_min || 0)
        total_value_max += parseFloat(child.total_value_max || 0)
      }

      // Update the parent line's total values
      this.$set(line, 'surface', total_surface.toFixed(2))
      this.$set(line, 'total_value_min', total_value_min.toFixed(2))
      this.$set(line, 'total_value_max', total_value_max.toFixed(2))

      // Clear out factor, and min/max price per sq m values.
      // They are not relevant when a line has children, as the totals are computed.
      this.$set(line, 'factor', null)
      this.$set(line, 'price_per_square_meter_min', null)
      this.$set(line, 'price_per_square_meter_max', null)

      return line
    },
    calculateTotals (line) {
      const { surface, factor, price_per_square_meter_min, price_per_square_meter_max } = line
      if (!surface || !factor) return line

      const surfaceNum = parseFloat(surface)
      const factorNum = parseFloat(factor) / 100

      if (price_per_square_meter_min) {
        const total_value_min = (surfaceNum * parseFloat(price_per_square_meter_min) * factorNum)
        this.$set(line, 'total_value_min', total_value_min.toFixed(2))
      }

      if (price_per_square_meter_max) {
        const total_value_max = (surfaceNum * parseFloat(price_per_square_meter_max) * factorNum)
        this.$set(line, 'total_value_max', total_value_max.toFixed(2))
      }

      return line
    },

    handleEstimateValueUpdate (key, value) {
      const payload = { [key]: value }
      return updatePropertyEstimate(this.propertyId, this.estimateId, payload)
    },
    epcLabelSelected (event) {
      let energeticFactor = null
      const estimated_epc_label = event.target.value || null

      if (estimated_epc_label) {
        const option = this.energyFactorOptions?.find(option => parseInt(option.epc_label) === parseInt(estimated_epc_label))
        if (option) {
          energeticFactor = option.value // value contains the energetic factor
          this.$set(this.estimate, 'energetic_factor', energeticFactor)
        }
      }

      return Promise.all([
        this.handleEstimateValueUpdate('energetic_factor', energeticFactor),
        this.handleEstimateValueUpdate('estimated_epc_label', estimated_epc_label)
      ])
    },

    filterVetusityOptions (type) {
      return this.vetusityConfig.filter(config => config.type === type)
    },

    async init () {
      this.loading = true
      const promises = await Promise.all([
        this.loadReasons(),
        this.fetchEstimateDetails(),
        this.fetchTopLevelOptions(),
        this.fetchFloorLevelOptions(),
        this.fetchVetusityConfigRecords()
      ])
      this.loading = false
      return promises
    },
    async loadReasons () {
      const params = { type__key: 'estimate_reason' }
      const response = await getReasons({ params })
      const reasons = response.data.results || []
      this.reasonOptions = reasons.map(item => {
        return { value: item.id, label: item.name }
      })
      return response
    },
    async fetchVetusityConfigRecords () {
      const response = await getVetusityConfigRecords()
      this.vetusityConfig = response.data?.results
      return response
    },
    async fetchEstimateDetails () {
      const response = await getPropertyEstimateDetails(this.propertyId, this.estimateId)
      const { reason, ...estimate } = response.data
      this.estimate = { reason: reason?.id, ...estimate }
      this.overrideToggles = {
        venalityMin: !!estimate.venality_min_override,
        venalityMax: !!estimate.venality_max_override,
        valueIntrinsic: !!estimate.value_intrinsic_override,
        valueAdvice: !!estimate.value_advice_override,
        valuePresale: !!estimate.value_presale_override
      }
      return response
    },
    async fetchTopLevelOptions () {
      // We need to fetch the level options for both the top most level, like parcel, construction, etc.
      const params = { with_parent: false, for_property: this.propertyId }
      const response = await getLevels(params)
      this.topLevelOptions = response.data?.results || []
      this.numberOfAllowedTopLines = response.data.count
      return response
    },
    async fetchFloorLevelOptions () {
      // We need to fetch the level options for both the floor levels, like first floor, second floor, etc.
      const params = { with_parent: true, for_property: this.propertyId } // It has a parent, i.e. the top most level
      const response = await getLevels(params)
      this.floorLevelOptions = response.data?.results || []
      return response
    },
    async updateLine (lineId, data) {
      if (!lineId) return false
      // We don't send the lines in the PATCH payload
      const { lines, ...payload } = data
      const response = await updateEstimateLine(this.propertyId, this.estimateId, lineId, payload)
      return response.data
    },
    async addLine (parent, index) {
      // Take the default min/max values of the estimate
      const { price_per_square_meter_min, price_per_square_meter_max } = this.estimate

      // index + 1 indicates the new item's index.
      // Since order starts with 1, not 0, we need to add 1 to get the order.
      const item = {
        name: '',
        lines: [],
        order: index + 2,
        parent: parent.id === this.estimate.id ? null : parent.id, // In case of top most level, parent is the estimate
        price_per_square_meter_min,
        price_per_square_meter_max
      }
      parent.lines.splice(index + 1, 0, item) // insert after current index
      const response = await createEstimateLine(this.propertyId, this.estimateId, item)
      this.$set(parent.lines[index + 1], 'id', response.data.id)
      return response
    },
    async deleteLine (parent, index, item, grandparent) {
      try {
        // Remove the item client side first, then make the API call
        (parent || this.estimate).lines.splice(index, 1) // If there is no parent, it means it is the top most level.

        // Recalculate parent totals after removing the line
        if (parent) this.calculateParentTotals(parent)
        if (grandparent) this.calculateParentTotals(grandparent)

        if (!item.id) return // No need to call the API if the item is not saved yet

        const response = await deleteEstimateLine(this.propertyId, this.estimateId, item.id)
        return response
      } catch (error) {
        parent.lines.splice(index, 0, item) // insert the item back again
        throw error
      }
    },
    async generateTableAsPdf () {
      try {
        this.$set(this.estimate, 'generating_table_pdf', true)

        const { language } = this.estimate
        const response = await getEstimationReportData(this.propertyId, this.estimateId, language)
        const lines = response.data?.lines

        const gridResponse = await generateEstimateReportGrid({ lines, language })
        const url = gridResponse?.data?.url
        window.open(url, '_blank')

        return [response, gridResponse]
      } catch (error) {
        errorModal('Er is een fout opgetreden bij het genereren van de PDF-tabel')
        throw error
      } finally {
        this.$set(this.estimate, 'generating_table_pdf', false)
      }
    }
  }
}
</script>

<style scoped>
.calculation-table th, td {
  @apply tw-p-0 tw-border tw-border-gray-e9;
}
.calculation-table td:nth-child(4),
.calculation-table td:nth-child(7),
.calculation-table td:nth-child(9) {
  @apply tw-bg-green-500 tw-bg-opacity-10;
}

.input-cell {
  @apply tw-px-3 tw-py-1.5 tw-w-full tw-bg-transparent disabled:tw-cursor-not-allowed;
}
.input-cell:invalid {
  border-radius: 2px;
  outline: 2px solid #cc5965;
}
.input-cell[type="number"] {
  @apply tw-text-right;
}

.select-name {
  @apply tw-absolute tw-right-1 tw-top-1/2 tw-w-4 tw--translate-y-1/2 tw-bg-transparent tw-cursor-pointer;
}

.add-button {
  @apply tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw--translate-x-1/2 tw-translate-y-1/2;
  @apply tw-px-1 tw-h-2 tw-w-2 tw-text-white tw-rounded tw-cursor-pointer tw-text-transparent;
  @apply tw-bg-gray-e9 hover:tw-bg-success hover:tw-h-auto hover:tw-w-auto hover:tw-text-white;
  @apply focus:tw-bg-success focus:tw-h-auto focus:tw-w-auto focus:tw-text-white
}
.delete-button {
  @apply tw-mx-1.5 tw-p-1.25 tw-my-0.5 tw-text-xs tw-rounded tw-text-danger hover:tw-bg-danger hover:tw-text-white focus:tw-bg-danger focus:tw-text-white;
}
.edit-button {
  @apply tw-px-1.5 tw-py-0.5 tw-bg-white tw-rounded-full tw-border tw-border-white hover:tw-shadow-card focus:tw-shadow-card hover:tw-text-success focus:tw-text-success hover:tw-border-success focus:tw-border-success;
}

.calculation-input {
  @apply tw-px-2 tw-h-8 tw-text-sm tw-rounded tw-border tw-border-gray-cc tw-text-right disabled:tw-opacity-60 focus:tw-border-tg-color invalid:!tw-border-red-600 !tw-outline-none;
}
.calculation-select {
  @apply tw-mt-0 tw-h-8 tw-text-sm tw-w-[90px] sm:tw-w-[170px];
}
</style>
